<template>
  <div class="newspage-container">
    <van-sticky>
      <head-bar title="动态" @onClickLeft="onClickLeft" />
    </van-sticky>
  </div>
</template>

<script>
import headBar from '@/components/headBar/headBar'
export default {
  name: 'newsPage',
  components: { headBar },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
</style>